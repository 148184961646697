import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.scss'

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  return (

    <div className='navbar'>

      <div className='wrapper'>

        <div className='left'>
          <div className='logo left fade-in-down'>
            <a href='#home'>
              <h1>freshpressed</h1>
            </a>
          </div>
        </div>

        <div className='right'>
          <div className='item'>
            <a className='link' href='#consulting'>Consulting</a>
          </div>
          <div className='item'>
            <a className='link' href='#about'>About</a>
          </div>
          <div className='item'>
            <a className='link' href='#gallery'>Gallery</a>
          </div>
          <div className='item'>
            <a className='link' href='#features'>Features</a>
          </div>
          <div className='item'>
            <a className='link' href='#contact'>Contact</a>
          </div>
        </div>

        <div className='menu'>
          <MenuIcon color='#fff' fontSize='18px' className="open" onClick={() => setToggleMenu(true)} />
          {toggleMenu && (
            <div className='overlay slide-bottom' >
              <CloseIcon color='#fff' fontSize='18px' className="close" onClick={() => setToggleMenu(false)} />
              <ul className='menu-links'>
                <li><a href='#consulting' onClick={() => setToggleMenu(false)}>Consulting</a></li>
                <li><a href='#about' onClick={() => setToggleMenu(false)}>About</a></li>
                <li><a href='#gallery' onClick={() => setToggleMenu(false)}>Gallery</a></li>
                <li><a href='#features' onClick={() => setToggleMenu(false)}>Features</a></li>
                <li><a href='#contact' onClick={() => setToggleMenu(false)}>Contact</a></li>
              </ul>
            </div>)}
        </div>

      </div>

    </div>

  )
}

export default Navbar