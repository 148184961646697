import { Navbar, Footer } from './components';
import { About, Contact, Consulting, Home, Events, Gallery, Features } from './pages';
import { Helmet } from 'react-helmet';
import './App.scss';

function App() {
  return (
    <>
      <Helmet>
        <title>Fresh Pressed Beverage by Judy Elahi</title>
        <meta name='description' content='Fresh Pressed Beverage, a Washington, DC-based Beverage Consulting and Event Company led by Judy Elahi'></meta>
        <meta name='keywords' content='freshpressed, judi elahi, beverage consulting, washington dc, craft cocktails, menu creation, menu development, corporate event catering, non-alcoholic cocktail, cold-pressed juice, private events'></meta>
        <meta name='author' content='Judy Elahi'></meta>
      </Helmet>
      <div className="app">
        <Navbar />
        <Home />
        <Consulting />
        <Events />
        <Features />
        <About />
        <Gallery />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
