import { useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import './Contact.scss'

const SERVICE_ID = 'service_n3ajqs9'
const USER_ID = '8PSCxwab4y0Af4mls'
const TEMPLATE_ID = 'template_d6v42qo'

const Contact = () => {

    const ref = useRef(null)
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        });
        console.log(isIntersecting);
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isIntersecting) {
            ref.current.querySelectorAll("form").forEach((el) => {
                el.classList.add("grow");
            });
        } else {
            ref.current.querySelectorAll("form").forEach((el) => {
                el.classList.remove("grow");
            });
        }
    }, [isIntersecting]);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((res) => {
                console.log(res.text);
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully, Thanks!'
                })
            }, (error) => {
                console.log(error.text)
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, something went wrong',
                    text: error.text,
                })
            })
        e.target.reset()
    }

    return (

        <div className='contact' id='contact'>
            <h1>Contact</h1>
            <div className='wrapper'>
                <div className='left'>
                    <div className='info'>
                        <h2>Questions or Inquiries?</h2>
                        <p>If you have any questions or would like to know more, feel free to contact me! </p>
                    </div>
                </div>
                <div className='right grow' id="form" ref={ref}>
                    <form onSubmit={sendEmail} className='form'>
                        <input
                            className='input'
                            type='text'
                            name='user_name'
                            placeholder='Name' />
                        <input
                            className='input'
                            type='text'
                            name='user_email'
                            placeholder='Email' />
                        <textarea
                            className='message'
                            name='message'
                            placeholder='Message' />
                        <button
                            className='form-btn'
                            type='submit'
                            value='send'>
                            SUBMIT
                        </button>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Contact