import bev1 from './bev/bev1.jpg';
import bev2 from './bev/bev2.jpg';
import bev3 from './bev/bev3.jpg';
import bev4 from './bev/bev4.jpg';
import bev5 from './bev/bev5.jpg';
import bev51 from './bev/bev5-1.jpg';
import bev52 from './bev/bev5-2.jpg';
import bev53 from './bev/bev5-3.jpg';
import bev54 from './bev/bev5-4.jpg';
import bev55 from './bev/bev55.png';
import bev6 from './bev/bev6.jpg';
import bev7 from './bev/bev7.jpg';
import bev8 from './bev/bev8.jpg';
import bev9 from './bev/bev9.jpg';
import bev10 from './bev/bev10.jpg';
import bev11 from './bev/bev11.png';
import bevTrio from './bev/bevTrio.png';
import bevHome from './bev/bevHome.png';
import judy1 from './judy/judy1.jpg';
import judy2 from './judy/judy2.jpeg';
import judy3 from './judy/judy3.jpg';
import judy4 from './judy/judy4.jpg';
import judy5 from './judy/judy5.jpg';
import judy6 from './judy/judy6.jpg';
import judy69 from './judy/judy69.jpg';
import judy66 from './judy/judy66.jpg';
import judy7 from './judy/judy7.jpg';
import judy8 from './judy/judy8.jpg';
import judy9 from './judy/judy9.jpg';
import judy10 from './judy/judy10.jpg';
import forbes from './logos/forbes-black.png';
import liquor from './logos/liquor-black.png'
import districtfray from './logos/districtfray.png'
import instagram from './logos/instagram.png'
import cosmopolitan from './logos/cosmopolitan.png'
import vinepair from './logos/vinepair2.png'
import thirsty from './logos/thirsty.png'
import chilled from './logos/chilled.png'

export default {
    bev1,
    bev2,
    bev3,
    bev4,
    bev5,
    bev51,
    bev52,
    bev53,
    bev54,
    bev55,
    bev6,
    bev7,
    bev8,
    bev9,
    bev10,
    bev11,
    bevHome,
    bevTrio,
    judy1,
    judy2,
    judy3,
    judy4,
    judy5,
    judy6,
    judy69,
    judy66,
    judy7,
    judy8,
    judy9,
    judy10,
    forbes,
    liquor,
    districtfray,
    instagram,
    cosmopolitan,
    vinepair,
    thirsty,
    chilled
}