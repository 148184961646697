import { useRef, useState, useEffect } from 'react'
import { images } from '../../assets';
import './About.scss';
import { Fade } from '@mui/material';

const About = () => {

  // const ref = useRef(null)
  // const [isIntersecting, setIsIntersecting] = useState(false);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(([entry]) => {
  //     setIsIntersecting(entry.isIntersecting);
  //   });
  //   console.log(isIntersecting);
  //   observer.observe(ref.current);
  //   return () => observer.disconnect();
  // }, []);

  // useEffect(() => {
  //   if (isIntersecting) {
  //     ref.current.querySelectorAll("div.title").forEach((el) => {
  //       el.classList.add("fade-in-right");
  //     });
  //   } else {
  //     ref.current.querySelectorAll("div.title").forEach((el) => {
  //       el.classList.remove("fade-in-right");
  //     });

  //   }
  // }, [isIntersecting]);

  function FadeInSection(props) {
    const [isVisible, setVisible] = useState(true);
    const domRef = useRef();
    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.disconnect();
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  return (
    <div className='about' id='about'>
      <div className='top'>
        <div className='title' >
          <h1 >Corporate Beverage Consultation</h1>
        </div>
        {/* <h3>Mixologist | Corporate Bar Director</h3>
        <div className='spacer'></div> */}
      </div>
      <div className='bottom'>
        <div className='left'>
          <img src={images.judy9} alt='' />
        </div>
        {/* <div className=''>
          <p>Judy Elahi</p>
          <p>Mixologist | Corporate Bar Director</p>
        </div> */}
        <div className='right'>
          <FadeInSection>
            <div className='card'>
              <h2>Menu Creation & Development</h2>
              <p>
                Fresh Pressed Beverage will work directly with you to develop or concept new menus, offerings, and
                experiences.
              </p>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className='card'>
              <h2>Corporate Event Elevation & Consultation</h2>
              <p>
                Elevate your bar and restaurant experience with an array of tailored services, such as
                delightful happy hours, captivating cocktail showcases, team-building workshops, staff
                training and growth, and conceptual rejuvenation for your dedicated teams.
              </p>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className='card'>
              <h2>Non-Alcoholic & Cold-Pressed Juice Production </h2>
              <p>
                Creating refreshing and health-conscious beverages, including non-alcoholic cocktails,
                cold-pressed juices, and hot beverages. Our offerings are designed to be healthy,
                inclusive, and meticulously crafted.
              </p>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className='card'>
              <h2>Corporate and Private Events</h2>
              <p>
                Fresh Pressed Beverage specializes in beverage operations for corporate events,
                adding a touch of sophistication and freshness to your business gatherings.
                We can also deliver unforgettable experiences for your loved ones, ensuring every
                moment is memorable.
              </p>
            </div>
          </FadeInSection>

          {/* <p>
            Meet Judy Elahi, the visionary mind behind Fresh Pressed Beverage, a venture that
            sprouted amidst the turmoil of COVID in 2020. Raised with the influence of her Iranian
            father, Judy's childhood was steeped in hospitality and culinary delights. Fond memories
            of preparing traditional Persian dishes alongside her father for loved ones shaped her
            passion for the culinary arts from an early age. Yet, it wasn't until later that she
            discovered her true canvas for expression.
          </p>
          <p>
            With a flair for art and technology during her high school days, Judy felt like an artist
            searching for her medium. Fortunately, her father's work in IT for a culinary school
            granted her a peek into the world of gastronomy. Sneaking off to explore the kitchen,
            she was captivated by the vast array of spices, produce, and endless possibilities
            that awaited within the professional setting.
          </p>
          <p>
            Driven by her newfound culinary fascination, Judy enrolled in the culinary arts program
            during her sophomore year. Her love for cooking blossomed, and on weekends, she honed her
            skills assisting in organizing and preparing catering events in the Washington, DC suburbs.
            A Johnson & Wales Culinary Scholarship in her junior year opened up even more opportunities
            for culinary exploration.
          </p>
          <p>
            Despite her parents' desire for a more traditional education, Judy's thirst for culinary
            knowledge couldn't be quenched. Her adventures led her to Elmira College in Upstate New York,
            although the small town failed to accommodate her big personality. Returning to the DMV, she
            immersed herself in the restaurant industry, working as a server and bartender.
          </p>
          <p>
            Judy's journey truly took flight when she delved into bartending for premier restaurants and
            nightclubs in the D.C. area. A pivotal moment at True Food Kitchen propelled her into the world
            of restaurant management, where she contributed to the opening of multiple restaurants. Yet,
            she yearned for more creative freedom, which led her to become the Beverage Director and
            Assistant General Manager for Blend 111 in Vienna, VA. Having already been a part of seventeen
            restaurant and café openings by the age of 32, her determination and passion were undeniable.
          </p>
          <p>
            With an insatiable curiosity and boundless creativity, Judy crafts cocktails that elevate the
            art of mixology. Embracing cold-pressed juices, fresh ingredients, and small-batch spirits, she
            strives to create unforgettable experiences for her guests. Emphasizing the importance of
            transparency and accountability in the alcohol industry, Judy selects spirits that empower
            farmers, distillers, and communities.
          </p>
          <p>
            Driven by her belief that every sip has the power to brighten someone's day, Judy dedicates
            herself to making a positive impact, one drink at a time.
          </p>
          <p>
            Fresh Pressed Beverage is more than a consulting & event company; it's a manifestation of Judy's
            unwavering commitment to hospitality and the art of crafting fresh, memorable cocktails.
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default About