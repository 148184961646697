import { useRef, useState, useEffect } from 'react'
import './Features.scss'
import { images } from '../../assets';

const Features = () => {
    const ref = useRef(null)
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        });
        console.log(isIntersecting);
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isIntersecting) {
            ref.current.querySelectorAll("div.left").forEach((el) => {
                el.classList.add("bounce-in-left");
            });
            ref.current.querySelectorAll("div.right").forEach((el) => {
                el.classList.add("bounce-in-right");
            });
        } else {
            ref.current.querySelectorAll("div.left").forEach((el) => {
                el.classList.remove("bounce-in-left");
            });
            ref.current.querySelectorAll("div.right").forEach((el) => {
                el.classList.remove("bounce-in-right");
            });
        }
    }, [isIntersecting]);

    return (
        <div className='features' id='features' >
            <div className='top'>
                <div className='line'></div>
                <h4>FEATURED IN</h4>
                <div className='line'></div>
            </div>
            <div className='middle' ref={ref}>
                <div className='logo right bounce-in-right'>
                    <a href='https://www.cosmopolitan.com/lifestyle/g43577590/tequila-drinks/' target="_blank" rel="noreferrer noopener"><img src={images.cosmopolitan} alt='' /></a>
                </div>
                <div className='logo left bounce-in-left'>
                    <a href='https://thirstymag.com/bartenders-against-bans-dc-2022/' target="_blank" rel="noreferrer noopener"><img src={images.thirsty} alt='' /></a>
                </div>
                <div className='logo right bounce-in-right'>
                    <a href='https://www.liquor.com/spiked-agua-fresca-6256720' target="_blank" rel="noreferrer noopener"><img src={images.liquor} alt='' /></a>
                </div>
                <div className='logo left bounce-in-left'>
                    <a href='https://www.forbes.com/sites/karlaalindahao/2021/12/31/easy-cocktail-recipes-for-those-not-celebrating-dry-january-2022/?sh=7443117559ce' target="_blank" rel="noreferrer noopener"><img src={images.forbes} alt='' /></a>
                </div>
                <div className='logo right bounce-in-right'>
                    <a href='https://vinepair.com/articles/wa-bartenders-underrated-cocktail-bitters/' target="_blank" rel="noreferrer noopener"><img src={images.vinepair} alt='' /></a>
                </div>
                <div className='logo left bounce-in-left'>
                    <a href="https://districtfray.com/?s=%22judy+elahi%22" target="_blank" rel="noreferrer noopener"><img src={images.districtfray} alt='' /></a>
                </div>
                <div className='logo right bounce-in-right'>
                    <a href="https://chilledmagazine.com/we-ask-bar-director-judy-elahi-about-dei-in-hospitality/" target="_blank" rel="noreferrer noopener"><img src={images.chilled} alt='' /></a>
                </div>
            </div>
            <div className='bottom'>
                <div className='line'></div>
            </div>
        </div>
    )
}

export default Features