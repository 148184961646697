import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import { images } from '../../assets'
import './Footer.scss';

const currentDate = new Date()

const Footer = () => {
    return (
        <div className='footer'>
            <div className='social'>
                <div className='icon'>
                    <a
                        href='https://www.instagram.com/_freshpressed_/'
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img className='instagram' src={images.instagram} alt='' />
                        {/* <InstagramIcon fontSize='4rem' style={{ color: '#eee' }} /> */}
                    </a>
                </div>
            </div>
            <div className='copyright'>
                <p className='title'>freshpressed</p>
                <span> © {currentDate.getFullYear()}</span>
            </div>
        </div>
    )
}

export default Footer