import './Consulting.scss';

const Consulting = () => {
    return (
        <div className='consulting' id='consulting'>
            <h1>
                Fresh Pressed Beverage
            </h1>
            <p>
                Introducing Fresh Pressed Beverage, a Washington, DC-based Beverage Consulting and Event Company led by Judy Elahi,
                corporate beverage director for Ethos Hospitality with nearly two decades of expertise.
            </p>
            <p>
                Judy Elahi, the founder of Fresh Pressed Beverage, emerged during the tumultuous times of COVID in 2020. With a rich
                Iranian heritage, her culinary journey began in high school and evolved into a successful career in the restaurant
                industry, where she honed her skills. Judy's remarkable journey led her to become a recognized Beverage Director,
                contributing to the opening of numerous bars, hotels, restaurants, and cafes in the DC area.
            </p>
            <p>
                Judy's forte lies in crafting cocktails that elevate the art of mixology. She skillfully utilizes cold-pressed juices,
                fresh ingredients, and small-batch spirits to create unforgettable experiences. Fresh Pressed Beverage embodies her
                dedication to crafting fresh and memorable cocktails.
            </p>
            <p>
                Our mission is to curate authentic beverage experiences, featuring a range of offerings, from fresh pressed cocktails to
                coffee, tea, and cold-pressed juice. Additionally, we take pride in promoting and supporting minority, female, and small
                craft distilleries.
            </p>
            <p>
                Step into the world of Fresh Pressed Beverages, where true craftsmanship meets your palate.
            </p>
            <p className='questions'>
                Questions or Inquiries?
            </p>
            <button>
                <a href='#contact' >Contact Us!</a>
            </button>
        </div>
    )
}

export default Consulting