import React from 'react'
import './Events.scss';

const Events = () => {
    return (
        <div className='events'>

        </div>
    )
}

export default Events