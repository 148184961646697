import { useState, useRef, useEffect } from 'react';
import { images } from '../../assets';
import Carousel from '../../components/Carousel/Carousel'
import './Gallery.scss';

const Gallery = () => {

    function FadeInSection(props) {
        const [isVisible, setVisible] = useState(true);
        const domRef = useRef();
        useEffect(() => {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => setVisible(entry.isIntersecting));
            });
            observer.observe(domRef.current);
            return () => observer.disconnect();
        }, []);
        return (
            <div
                className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                ref={domRef}
            >
                {props.children}
            </div>
        );
    }

    return (
        <div className='gallery' id='gallery'>
            <div className='wrapper'>
                <h2>Gallery</h2>
                <div className='col'>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.judy6} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.judy7} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.judy4} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev1} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev9} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev7} alt='' />
                        </div>
                    </FadeInSection>
                </div>
                <div className='col'>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev2} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev4} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev3} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.judy5} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.bev8} alt='' />
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className='item'>
                            <img src={images.judy3} alt='' />
                        </div>
                    </FadeInSection>
                </div>
            </div>


        </div>
    )
}

export default Gallery