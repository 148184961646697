import { useRef, useState, useEffect } from 'react'
import './Home.scss';
import { images } from '../../assets';

export default function Home() {

    const imageUrl = window.innerWidth >= 450 ? images.bev53 : images.bev53;

    const ref = useRef(null)
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        });
        console.log(isIntersecting);
        observer.observe(ref.current);
        return () => observer.disconnect();
    });

    useEffect(() => {
        if (isIntersecting) {
            ref.current.querySelectorAll("div.down").forEach((el) => {
                el.classList.add("fade-in-down");
            });
            ref.current.querySelectorAll("div.left").forEach((el) => {
                el.classList.add("fade-in-left");
            });
        } else {
            ref.current.querySelectorAll("div.down").forEach((el) => {
                el.classList.remove("fade-in-down");
            });
            ref.current.querySelectorAll("div.left").forEach((el) => {
                el.classList.remove("fade-in-left");
            });
        }
    }, [isIntersecting]);

    return (
        <div className='home' id='home' style={{ backgroundImage: `url(${imageUrl})` }} ref={ref}>
            <div className='down fade-in-left' >
                <h1 className='title'><span>by</span> Judy Elahi</h1>
            </div>
        </div>
    )
}